import * as Sentry from '@sentry/browser'

import { IUser } from 'hooks/useUserSession'

// NOTE: this is currently only getting called client-side. So errors in getServerSideProps do not have a user context yet :-(
export function setSentryUser(user: IUser) {
  Sentry.setUser({ id: user.id.toString(), email: user.email, username: `${user.firstName} ${user.lastName}` })
}

export function clearSentryUser() {
  Sentry.configureScope(scope => scope.setUser(null))
}
