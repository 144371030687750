// Based off this example: https://github.com/vercel/next.js/blob/canary/examples/with-apollo/lib/apolloClient.js
import { SentryLink } from 'apollo-link-sentry'
import { useMemo } from 'react'
import { ApolloClient, InMemoryCache, ApolloLink, setLogVerbosity, NormalizedCacheObject } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import merge from 'deepmerge'
import isEqual from 'lodash.isequal'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined = undefined

// Log apollo errors to console in development
if (!process.env.NEXT_PUBLIC_IS_PRODUCTION) {
  setLogVerbosity('debug')
}

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([
      // Adds graphql information to Sentry breadcrumbs, so the info gets logged with any Sentry errors
      new SentryLink({
        uri: `${process.env.NEXT_PUBLIC_API_URL?.replace('/v0', '')}/graphql`,
        attachBreadcrumbs: {
          includeQuery: true,
          includeVariables: true,
          includeError: true
        }
      }),

      // NOTE: seems to be a conflict of apollo versions with what apollo-upload-client is using, hence the `as unknown as ApolloLink` cast
      createUploadLink({
        uri: `${process.env.NEXT_PUBLIC_API_URL?.replace('/v0', '')}/graphql`,
        credentials: 'include'
      }) as unknown as ApolloLink
    ]),
    connectToDevTools: !process.env.NEXT_PUBLIC_IS_PRODUCTION,
    cache: new InMemoryCache({
      typePolicies: {
        // If doing some kind of infinite scrolling, append results:
        //
        // import { concatPagination } from '@apollo/client/utilities'
        // Catalog: {
        //   fields: {
        //     //draftProducts: concatPagination(),
        //     draftProducts: {
        //       keyArgs: [],
        //       // merge(existing, incoming, args) {
        //       //   const { page = 1, perPage = 50 } = args
        //       //   const offset = page * perPage - 1
        //       //   console.log('GOT HERE', args, offset, existing, incoming)
        //       //   // Slicing is necessary because the existing data is
        //       //   // immutable, and frozen in development.
        //       //   const merged = existing ? existing.slice(0) : []
        //       //   for (let i = 0; i < incoming.length; ++i) {
        //       //     merged[offset + i] = incoming[i]
        //       //   }
        //       //   return merged
        //       // }
        //     }
        //   }
        // }
      }
    })
  })
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s)))
      ]
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state), [state])
  return store
}
