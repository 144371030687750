/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/nextjs'
import { DocumentNode, ExecutionResult } from 'graphql'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import {
  useMutation as useApolloMutation,
  ApolloCache,
  DefaultContext,
  OperationVariables,
  MutationHookOptions,
  MutationTuple,
  MutationFunctionOptions,
  ApolloError
} from '@apollo/client'

import { useToast } from 'components/Toast'
import formatApolloError from 'lib/format-apollo-error'

interface MutationOptions<TData, TVariables, TContext> extends MutationHookOptions<TData, TVariables, TContext> {
  toastOptions?: {
    successMessage?: string | React.ReactNode
    defaultErrorMessage?: string | React.ReactNode
    suppress?: boolean
  }
}

/*
 * Wraps the default useMutation function with extra options for triggering
 * Toast's when there is an error
 *
 * @see: https://github.com/apollographql/apollo-client/blob/main/src/react/hooks/useMutation.ts
 */
export function useMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationOptions<TData, TVariables, TContext>
): MutationTuple<TData, TVariables, TContext, TCache | ApolloCache<any>> {
  const { toastOptions, onError, ...fnOptions } = options || {}
  const { suppress, successMessage, defaultErrorMessage } = toastOptions || {}

  const [showToast] = useToast()
  const [_executeMutation, result] = useApolloMutation(mutation, {
    ...fnOptions,
    onError: error => {
      Sentry.captureException(error)
      onError?.(error)
    }
  })

  const execute = async (
    newOptions?: MutationFunctionOptions<TData, TVariables, TContext, ApolloCache<any>>
  ): Promise<ExecutionResult<any>> => {
    try {
      const response = await _executeMutation(newOptions)
      const firstEntry = ((Object.entries(response?.data ?? {}) ?? [])[0] ?? [])[1] as any
      const success: boolean = firstEntry?.success
      const errors = (firstEntry?.errors ?? []) as Array<any>
      const firstBaseError = errors.find((e: any) => (e.path || []).length === 0 || e.path?.[0] === 'base')
      const hasPageErrors = errors.some((e: any) => !((e.path || []).length === 0 || e.path?.[0] === 'base'))
      const fallbackErrorMessage = hasPageErrors ? 'Please check the page for errors' : 'Something went wrong'

      if (successMessage && success) {
        showToast({ kind: 'success', message: successMessage })
      }

      /* typical response format:
       *
       * {
       *   data: {
       *     cancelProductsCsv: {
       *       errors: [
       *         {
       *           code: null,
       *           message: 'Products CSV upload is not in progress. Nothing to cancel',
       *           path: [],
       *           __typename: 'UserError'
       *         }
       *       ],
       *       success: false,
       *       __typename: 'CancelProductsCSVMutationPayload'
       *     }
       *   }
       * }
       */

      if (!success && !suppress) {
        if (firstBaseError) {
          showToast({ kind: 'error', message: firstBaseError.message || defaultErrorMessage || fallbackErrorMessage })
        } else {
          // This can happen if there is a javascript error in onCompleted Apollo callback, so just print to console for now.
          if (!response.data && response.errors) {
            console.error(response)
          }

          showToast({ kind: 'error', message: defaultErrorMessage || fallbackErrorMessage })
        }
      }

      return response
    } catch (err) {
      if (err instanceof ApolloError) {
        const formattedError = formatApolloError(err, defaultErrorMessage)
        if (!suppress && formattedError) {
          showToast({ kind: 'error', message: formattedError })
        }
      }

      throw err
    }
  }

  return [execute, { ...result }]
}
