/* eslint-disable @typescript-eslint/no-explicit-any */

import * as _ from 'lodash'

/**
 * @example
 *   import keysToCamelCase from './snake-to-camel-case'
 *   keysToCamelCase({bad_key: 1})   => {badKey: 1}
 *   keysToCamelCase([{bad_key: 1}]) => [{badKey: 1}]
 */

function keysToCamelCase(object: any): any {
  let camelCaseObject = _.cloneDeep(object)

  if (_.isString(object)) {
    return object
  }

  if (_.isNumber(object)) {
    return object
  }

  if (_.isBoolean(object)) {
    return object
  }

  if (_.isArray(camelCaseObject)) {
    return _.map(camelCaseObject, keysToCamelCase)
  }

  camelCaseObject = _.mapKeys(camelCaseObject, (_value, key) => {
    return _.camelCase(key)
  })

  // Recursively apply throughout object
  return _.mapValues(camelCaseObject, value => {
    if (_.isPlainObject(value)) {
      return keysToCamelCase(value)
    }

    if (_.isArray(value)) {
      return _.map(value, keysToCamelCase)
    }

    return value
  })
}

export default keysToCamelCase
