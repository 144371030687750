/* eslint-disable @typescript-eslint/no-explicit-any */

import * as _ from 'lodash'

/**
 * @example
 *   import keysToSnakeCase from './camel-to-snake-case'
 *   keysToSnakeCase({badKey: 1})   => {bad_key: 1}
 *   keysToSnakeCase([{badKey: 1}]) => [{bad_key: 1}]
 */

type SnakeCaseExceptions = {
  [key: string]: string
}

// These columns don't convert to how the backend has defined them, so overide
const exceptions: SnakeCaseExceptions = {
  street1: 'street_1',
  street2: 'street_2',
  phone2: 'phone_2',
  taxRate1: 'tax_rate_1',
  taxRate1InPercent: 'tax_rate_1_in_percent',
  taxRate1Label: 'tax_rate_1_label',
  tax1Amount: 'tax_1_amount',
  tax1Label: 'tax_1_label',
  tax1Rate: 'tax_1_rate',
  taxable1: 'taxable_1',
  taxRate2InPercent: 'tax_rate_2_in_percent',
  taxRate2: 'tax_rate_2',
  taxRate2Label: 'tax_rate_2_label',
  tax2Amount: 'tax_2_amount',
  tax2Label: 'tax_2_label',
  tax2Rate: 'tax_2_rate',
  taxable2: 'taxable_2',
  line1: 'line1',
  line2: 'line2',
  variety1Id: 'variety1_id',
  variety1Name: 'variety1_name',
  variety1Description: 'variety1_description',
  variety1LinkTarget: 'variety1_link_target',
  variety1LinkCategory: 'variety1_link_category',
  variety2Id: 'variety2_id',
  variety2Name: 'variety2_name',
  variety2Description: 'variety2_description',
  variety2LinkTarget: 'variety2_link_target',
  variety2LinkCategory: 'variety2_link_category',
  variety3Id: 'variety3_id',
  variety3Name: 'variety3_name',
  variety3Description: 'variety3_description',
  variety3LinkTarget: 'variety3_link_target',
  variety3LinkCategory: 'variety3_link_category',
  variety4Id: 'variety4_id',
  variety4Name: 'variety4_name',
  variety4Description: 'variety4_description',
  variety4LinkTarget: 'variety4_link_target',
  variety4LinkCategory: 'variety4_link_category'
}

function keysToSnakeCase(object: any): any {
  let snakeCaseObject = _.cloneDeep(object)

  if (_.isString(object)) {
    return object
  }

  if (_.isNumber(object)) {
    return object
  }

  if (_.isBoolean(object)) {
    return object
  }

  if (_.isArray(snakeCaseObject)) {
    return _.map(snakeCaseObject, keysToSnakeCase)
  }

  snakeCaseObject = _.mapKeys(snakeCaseObject, (_value, key) => {
    if (exceptions[key]) return exceptions[key]
    else return _.snakeCase(key)
  })

  // Recursively apply throughout object
  return _.mapValues(snakeCaseObject, value => {
    if (_.isPlainObject(value)) {
      return keysToSnakeCase(value)
    }

    if (_.isArray(value)) {
      return _.map(value, keysToSnakeCase)
    }

    return value
  })
}

export default keysToSnakeCase
