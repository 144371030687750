import { useState, useEffect, useCallback } from 'react'
import classnames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'
import upperFirst from 'lodash.upperfirst'

import Icon from 'components/Icon'
import styles from './Toast.module.css'

export type ToastProps = {
  className?: string
  message?: string | React.ReactNode
  kind?: 'info' | 'success' | 'error' | 'warning'
  title?: string
  autoDismiss?: boolean
  dismissable?: boolean
  onDismiss?: () => void
  customComponent?: React.ReactNode
}

const TIMEOUT_DELAY = 10000
const CLOSE_DELAY = 250

const kindToIcon = {
  info: 'alert-circle',
  error: 'alert-circle',
  success: 'check-circle',
  warning: 'alert-triangle'
}

const Toast = ({
  className,
  title,
  dismissable = false,
  kind = 'info',
  onDismiss,
  autoDismiss = true,
  message,
  customComponent
}: ToastProps) => {
  const [isClosed, setIsClosed] = useState(false)

  const onDismissClick = useCallback(() => {
    setIsClosed(true)
    onDismiss?.()
  }, [onDismiss])

  const dismissWithDelay = useDebouncedCallback(() => {
    setIsClosed(true)
    if (onDismiss) {
      setTimeout(onDismiss, CLOSE_DELAY)
    }
  }, TIMEOUT_DELAY)

  useEffect(() => {
    if (autoDismiss) {
      dismissWithDelay()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isClosed) return <></>

  return (
    <>
      {customComponent ? (
        <>{customComponent}</>
      ) : (
        <div
          className={classnames(styles.toast, styles[kind], className, {
            [styles.dismissable]: dismissable
          })}>
          <div className={styles.toastInner}>
            <Icon className={styles.toastIcon} kind={kindToIcon[kind]} size={16} />

            <div className={styles.toastRight}>
              {title && (
                <div className={classnames(styles.toastTitle, styles[`toastTitle${upperFirst(kind)}`])}>{title}</div>
              )}
              <div className={styles.toastMessage}>{message}</div>
            </div>
          </div>

          {dismissable && <Icon kind="x" size={10} onClick={() => onDismissClick()} className={styles.dismiss} />}
        </div>
      )}
    </>
  )
}

export default Toast
