import { ReactNode, ReactElement } from 'react'
import type { AppProps } from 'next/app'
import { NextPage } from 'next'
import Head from 'next/head'
import Router from 'next/router'
import { ApolloProvider } from '@apollo/client'
import Script from 'next/script'
import NextNProgress from 'nextjs-progressbar'

import { ErrorContextProvider } from 'components/ErrorContext/ErrorContext'
import { ToastContextProvider } from 'components/Toast/ToastContext'
import IntercomProvider from 'components/Intercom'
import { ShoppingCartContextProvider } from 'modules/buyer-hub/checkout/components/ShoppingCartContext/ShoppingCartContext'
import { gaPageView } from 'lib/ga'
import 'lib/yup'

// Ambient declaration for polyfill
/// <reference path="../types/core-js-polyfill.d.ts"/>
// Added polyfill for Array.prototype.at
import 'core-js/es/array/at'

// NOTE: make sure any changes to this list of CSS files is reflected in .storybook/preview.js
import 'styles/variables.css'
import 'modules/website/pages/WholesalerPage/variables.css'
import 'styles/globals.css'
import 'styles/datepicker.css'

import { useApollo } from '../lib/apolloClient'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// NOTE: even though GA4 now automatically sents pageview events, this doesn't
// work with Next.js, so still need to manually send them.
Router.events.on('routeChangeComplete', path => {
  gaPageView(path)
})

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type FieldfolioAppProps = AppProps & {
  Component: NextPageWithLayout
}

function FieldfolioApp({ Component, pageProps }: FieldfolioAppProps) {
  const apolloClient = useApollo(pageProps)

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? (page => page)

  return (
    <ApolloProvider client={apolloClient}>
      <IntercomProvider>
        <ShoppingCartContextProvider>
          <ErrorContextProvider>
            <DndProvider backend={HTML5Backend}>
              <ToastContextProvider>
                <Head>
                  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                </Head>
                <NextNProgress color="var(--colorPrimary)" options={{ showSpinner: false }} />
                <Script src="/js/ie-warning.js" />
                {getLayout(
                  <>
                    {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
                    {/* See: https://github.com/vercel/next.js/blob/canary/examples/with-sentry/pages/_app.js */}
                    <Component {...pageProps} />
                  </>
                )}
              </ToastContextProvider>
            </DndProvider>
          </ErrorContextProvider>
        </ShoppingCartContextProvider>
      </IntercomProvider>
    </ApolloProvider>
  )
}

export default FieldfolioApp
