import React, { useCallback, useState, createContext } from 'react'

import Toast, { ToastProps } from './Toast'
import styles from './ToastContext.module.css'

type GlobalToastProps = Omit<ToastProps, 'autoDismiss'> & {
  active?: boolean
}

const ToastContext = createContext<
  [
    (props: ToastProps) => void, // showToast
    () => void // hideToast
  ]
>([() => null, () => null])

export default ToastContext

export const ToastContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [toast, setToast] = useState<GlobalToastProps>({
    message: '',
    active: false
  })

  const showToast = useCallback(
    ({ ...toastProps }: Omit<ToastProps, 'autoDismiss'>) => {
      setToast({
        active: true,
        ...toastProps
      })
    },
    [setToast]
  )

  const hideToast = useCallback(() => {
    setToast(prevState => ({
      ...prevState,
      active: false
    }))
  }, [setToast])

  return (
    <ToastContext.Provider value={[showToast, hideToast]}>
      {toast.active && (
        <div className={styles.globalToastWrapper}>
          <Toast {...toast} onDismiss={() => setToast({ active: false, message: '' })} />
        </div>
      )}

      {children}
    </ToastContext.Provider>
  )
}
