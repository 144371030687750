import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { NextApiRequest } from 'next'
import { IncomingMessage } from 'http'
import Cookies from 'universal-cookie'

import keysToCamelCase from 'lib/snake-to-camel-case'
import keysToSnakeCase from 'lib/camel-to-snake-case'

type StoreRequest = NextApiRequest | IncomingMessage
type CustomAxiosRequestConfig = AxiosRequestConfig & {
  snake?: boolean
  camel?: boolean
}

const onSuccessResponse = (response: AxiosResponse) => {
  const config: CustomAxiosRequestConfig = response.config
  if (config.camel === undefined || config.camel) {
    response.data = keysToCamelCase(response.data)
  }
  return response
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onErrorResponse = (error: any) => {
  if (error.response && (error.response.config?.camel === undefined || error.response.config?.camel)) {
    error.response.data = keysToCamelCase(error.response.data)
  }

  return Promise.reject(error)
}

class Axios {
  currentRequest?: StoreRequest

  create() {
    const extraHeaders = this.getExtraHeaders()
    const instance = axios.create({
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...extraHeaders
        // 'Cache-Control': 'no-cache'
      }
    })

    // Transform data to snakecase before sending to the server
    instance.interceptors.request.use(
      (config: CustomAxiosRequestConfig) => {
        // Do something before request is sent
        if (config.snake === undefined || config.snake) {
          config.data = keysToSnakeCase(config.data)
          config.params = keysToSnakeCase(config.params)
        }

        return config
      },
      error => {
        // Do something with request error
        return Promise.reject(error)
      }
    )

    instance.interceptors.response.use(onSuccessResponse, onErrorResponse)

    return instance
  }

  // Pass-through next.js session cookie to axios, incase we need to make server-side axios requests
  //
  // TODO: set this via some kind of middleware
  setNextJsRequest(req?: StoreRequest) {
    this.currentRequest = req
  }

  getExtraHeaders() {
    const req = this.currentRequest
    return req && req.headers.cookie ? { cookie: req.headers.cookie } : {}
  }

  getCookies() {
    const req = this.currentRequest
    return new Cookies((req && req.headers.cookie) || undefined)
  }
}

export const axiosInstance = new Axios()
