// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const gaPageView = (path: string) => {
  if (process.env.NEXT_PUBLIC_IS_PRODUCTION) {
    window.gtag('config', process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID, {
      page_path: path
    })
  } else {
    console.log('gaPageView:', path)
  }
}

/*
 * NOTE: the intention here, is to send events/conversion-events from the frontend to Google Analytics.
 *
 * However, some events can be sent from the backend, i.e. 'buyer_first_order', 'buyer_first_pricing_request' etc.
 *
 * See: api/app/services/conversions/google/event_service.rb
 */
export enum GAEvent {
  REP_SIGNUP = 'rep_sign_up',
  REP_ONBOARDING_COMPLETE = 'rep_complete_registration',

  BUYER_SIGNUP = 'buyer_sign_up',
  BUYER_ONBOARDING_COMPLETE = 'buyer_complete_registration',

  SELLER_SIGNUP = 'seller_sign_up',
  SELLER_ONBOARDING_COMPLETE = 'seller_complete_registration',

  PURCHASE = 'purchase',

  VIEW_ITEM = 'view_item',
  ADD_PAYMENT_INFO = 'add_payment_info'
}

type EventProps = {
  event: GAEvent
  params?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
}

export const getGACategories = (fullPath: string) => {
  const [category, category2, category3, category4, category5] = fullPath.split(' > ') ?? []

  const categoryObject = {
    item_category: category,
    item_category2: category2,
    item_category3: category3,
    item_category4: category4,
    item_category5: category5
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const filteredEntries = Object.entries(categoryObject).filter(([_key, value]) => value !== undefined)
  const availableCategories = Object.fromEntries(filteredEntries)

  return availableCategories
}

// 'params' in GA4 can be anything you want. But limited to 25 params
//
// More information: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
export const gaEvent = ({ event, params }: EventProps) => {
  if (process.env.NEXT_PUBLIC_IS_PRODUCTION) {
    if (window.gtag == null) {
      console.error('Google Tag is not setup!')
      return
    }

    window.gtag('event', event, params)
    console.log("gaEvent: 'event',", event, params)
  } else {
    console.log("gaEvent: 'event',", event, params)
  }
}
