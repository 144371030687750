import { createContext } from 'react'
import { useLazyQuery, LazyQueryResult, QueryLazyOptions, OperationVariables } from '@apollo/client'

import GET_DRAFT_ORDER from './graphql/GetDraftOrder.graphql'
import { GetDraftOrder, GetDraftOrderVariables } from './graphql/__generated__/GetDraftOrder'
import GET_DRAFT_ORDERS from './graphql/GetDraftOrders.graphql'
import { GetDraftOrders, GetDraftOrdersVariables } from './graphql/__generated__/GetDraftOrders'
import GET_DRAFT_ORDERS_SUMMARY from './graphql/GetDraftOrdersSummary.graphql'
import { GetDraftOrdersSummary } from './graphql/__generated__/GetDraftOrdersSummary'

export type FetchOrderType = (
  options?: QueryLazyOptions<GetDraftOrderVariables> | undefined
) => Promise<LazyQueryResult<GetDraftOrder, GetDraftOrderVariables>>

export type FetchOrderResult = LazyQueryResult<GetDraftOrder, GetDraftOrderVariables>

export type FetchOrdersType = (
  options?: QueryLazyOptions<GetDraftOrdersVariables> | undefined
) => Promise<LazyQueryResult<GetDraftOrders, GetDraftOrdersVariables>>

export type FetchOrdersResult = LazyQueryResult<GetDraftOrders, GetDraftOrdersVariables>

export type FetchOrdersSummaryType = (
  options?: QueryLazyOptions<OperationVariables> | undefined
) => Promise<LazyQueryResult<GetDraftOrdersSummary, OperationVariables>>

export type FetchOrdersSummaryResult = LazyQueryResult<GetDraftOrdersSummary, OperationVariables>

export const ShoppingCartContext = createContext<{
  fetchOrder: FetchOrderType
  fetchOrders: FetchOrdersType
  fetchOrdersSummary: FetchOrdersSummaryType
  fetchOrderResult: FetchOrderResult
  fetchOrdersResult: FetchOrdersResult
  fetchOrdersSummaryResult: FetchOrdersSummaryResult
}>({
  fetchOrder: (() => null) as unknown as FetchOrderType,
  fetchOrders: (() => null) as unknown as FetchOrdersType,
  fetchOrdersSummary: (() => null) as unknown as FetchOrdersSummaryType,
  fetchOrderResult: null as unknown as FetchOrderResult,
  fetchOrdersResult: null as unknown as FetchOrdersResult,
  fetchOrdersSummaryResult: null as unknown as FetchOrdersSummaryResult
})

export default ShoppingCartContext

export const ShoppingCartContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [fetchOrder, fetchOrderResult] = useLazyQuery<GetDraftOrder, GetDraftOrderVariables>(GET_DRAFT_ORDER, {
    // Each page change, will refetch the order, to ensure that nothing has changed in the backend
    // But otherwise, for subsequent renders on the same page, just read from the cache
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  })

  const [fetchOrders, fetchOrdersResult] = useLazyQuery<GetDraftOrders, GetDraftOrdersVariables>(GET_DRAFT_ORDERS, {
    // Each page change, will refetch the order, to ensure that nothing has changed in the backend
    // But otherwise, for subsequent renders on the same page, just read from the cache
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  })

  const [fetchOrdersSummary, fetchOrdersSummaryResult] = useLazyQuery<GetDraftOrdersSummary>(GET_DRAFT_ORDERS_SUMMARY)

  return (
    <ShoppingCartContext.Provider
      value={{
        fetchOrder,
        fetchOrderResult,
        fetchOrders,
        fetchOrdersResult,
        fetchOrdersSummary,
        fetchOrdersSummaryResult
      }}>
      {children}
    </ShoppingCartContext.Provider>
  )
}
