import { ServerResponse } from 'http'
import Router from 'next/router'

interface Options {
  href: string
  as?: string
  statusCode?: number
  res?: ServerResponse
  replaceRoute?: boolean
  refreshPage?: boolean
}

function redirect({ href, as, statusCode, res, replaceRoute, refreshPage }: Options) {
  if (res) {
    res.writeHead(statusCode ? statusCode : 302, {
      Location: as ? as : href
    })
    res.end()
    res.finished = true
  } else if (refreshPage) {
    window.document.location.href = as || href
  } else if (replaceRoute) {
    Router.replace(href, as ? as : href)
  } else {
    Router.push(href, as ? as : href)
  }
}

export default redirect
