import React, { useCallback, useState, createContext } from 'react'
import Error from 'next/error'

type RenderErrorProps = {
  statusCode?: number
  message?: string
}

type GlobalRenderErrorProps = RenderErrorProps & {
  active?: boolean
}

const ErrorContext = createContext<
  [
    (props: RenderErrorProps) => void // renderError
  ]
>([() => null])

export default ErrorContext

// This component uses context to render the Next.js error page/component
//
// It is useful for rendering an error page from deep within a component stack,
// i.e. like when working with GraphQL

export const ErrorContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<GlobalRenderErrorProps>({
    message: '',
    active: false
  })

  const renderError = useCallback(
    ({ ...errorProps }: RenderErrorProps) => {
      setError({
        active: true,
        ...errorProps
      })
    },
    [setError]
  )

  return (
    <ErrorContext.Provider value={[renderError]}>
      {error.active && <Error statusCode={error.statusCode || 500} />}

      {!error.active && <>{children}</>}
    </ErrorContext.Provider>
  )
}
