import { IntercomProvider as ReactIntercomProvider } from 'react-use-intercom'

import { IntercomContextProvider, isSSR } from './IntercomContext'

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID || ''
const shouldInitialize = Boolean(INTERCOM_APP_ID) && Boolean(process.env.NEXT_PUBLIC_INTERCOM_ENABLED) && !isSSR

type IntercomContextProps = {
  children: React.ReactNode
}

export const IntercomProvider = ({ children }: IntercomContextProps) => {
  // TODO: In the future, we should use the "test mode" region for intercom for development/staging
  return (
    <ReactIntercomProvider appId={INTERCOM_APP_ID} shouldInitialize={shouldInitialize}>
      {shouldInitialize && <IntercomContextProvider>{children}</IntercomContextProvider>}
      {!shouldInitialize && <>{children}</>}
    </ReactIntercomProvider>
  )
}

export default IntercomProvider
