import { ApolloError } from '@apollo/client'

/**
 * Format an error from apollo into a readable string
 */
const formatApolloError = (error: ApolloError, defaultMessage?: string | React.ReactNode) => {
  const graphQLErrors = error.graphQLErrors.map(({ message }) =>
    typeof message === 'object' ? 'Something went wrong when requesting data.' : message
  )
  if (graphQLErrors.length > 0 && (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development')) {
    console.error('GraphQLErrors:', error.graphQLErrors)
  }
  return graphQLErrors[0] || defaultMessage
}

export default formatApolloError
