import { setLocale, addMethod, number, string } from 'yup'

export const containsUrlRegexPattern =
  /\b(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.(co|com|org|net|gov|edu|co\.uk|com\.au|co\.nz)\b/i

// Customise Yup validation messages.
//
// Some of these are commented out, because they're either not available in this
// version of Yup, or I don't have access to functions like `printValue`

setLocale({
  mixed: {
    default: 'is invalid',
    required: 'Required',
    defined: 'must be defined',
    // notNull: 'cannot be null',
    oneOf: 'must be one of the following values: ${values}'
    // notOneOf: 'must not be one of the following values: ${values}',
    // notType: ({ path, type, value, originalValue }) => {
    //   const castMsg =
    //     originalValue != null && originalValue !== value
    //       ? ` (cast from the value \`${printValue(originalValue, true)}\`).`
    //       : '.'

    //   return type !== 'mixed'
    //     ? `must be a \`${type}\` type, ` + `but the final value was: \`${printValue(value, true)}\`` + castMsg
    //     : `must match the configured type. ` +
    //         `The validated value was: \`${printValue(value, true)}\`` +
    //         castMsg
    // }
  },
  string: {
    length: 'must be exactly ${length} characters',
    min: 'must be at least ${min} characters',
    max: 'must be at most ${max} characters',
    matches: 'must match the following: "${regex}"',
    email: 'must be a valid email',
    url: 'must be a valid URL',
    uuid: 'must be a valid UUID',
    trim: 'must be a trimmed string',
    lowercase: 'must be a lowercase string',
    uppercase: 'must be a upper case string'
  },
  number: {
    min: 'must be greater than or equal to ${min}',
    max: 'must be less than or equal to ${max}',
    lessThan: 'must be less than ${less}',
    moreThan: 'must be greater than ${more}',
    positive: 'must be a positive number',
    negative: 'must be a negative number',
    integer: 'must be an integer'
  },
  date: {
    min: 'field must be later than ${min}',
    max: 'field must be at earlier than ${max}'
  },
  boolean: {
    isValue: 'field must be ${value}'
  },
  object: {
    noUnknown: 'field has unspecified keys: ${unknown}'
  },
  array: {
    min: 'field must have at least ${min} items',
    max: 'field must have less than or equal to ${max} items'
    //length: 'must have ${length} items'
  }
  // tuple: {
  //   notType: params => {
  //     const { path, value, spec } = params
  //     const typeLen = spec.types.length
  //     if (Array.isArray(value)) {
  //       if (value.length < typeLen)
  //         return `tuple value has too few items, expected a length of ${typeLen} but got ${
  //           value.length
  //         } for value: \`${printValue(value, true)}\``
  //       if (value.length > typeLen)
  //         return `tuple value has too many items, expected a length of ${typeLen} but got ${
  //           value.length
  //         } for value: \`${printValue(value, true)}\``
  //     }

  //     return ValidationError.formatError(mixed.notType, params)
  //   }
  // }
})

/*
 * Custom method for use with Formik. Will coerce an empty string to undefined,
 * which is nessesary because GraphQL hates receiving empty strings for number
 * arguments
 */
addMethod(number, 'emptyStringToNull', function emptyStringToNull() {
  return this.transform((value, originalValue) => {
    if (typeof originalValue === 'string' && originalValue === '') {
      return null
    }
    return value
  })
})
addMethod(string, 'emptyStringToNull', function emptyStringToNull() {
  return this.transform((value, originalValue) => {
    if (typeof originalValue === 'string' && originalValue === '') {
      return null
    }
    return value
  })
})

/*
 * Custom method for use with formik validation to reject strings with URLs.
 */
addMethod(string, 'noUrlOrEmail', function noUrlOrEmail() {
  return this.test('noUrlOrEmail', 'URLs, emails or phone numbers are not permitted', function (string) {
    return !containsUrlRegexPattern.test(string || '')
  })
})
