import React from 'react'
import keyBy from 'lodash.keyby'
import kebabCase from 'lodash.kebabcase'

import {
  AlertCircle,
  AlertTriangle,
  AlertOctagon,
  ArrowDown,
  ArrowLeft,
  ArrowLeftCircle,
  ArrowRight,
  ArrowUp,
  Award,
  CreditCard,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Circle,
  Clipboard,
  Clock,
  Copy,
  DollarSign,
  Download,
  Edit,
  ExternalLink,
  Eye,
  Facebook,
  Feather,
  FileText,
  Filter,
  HelpCircle,
  Home,
  Image,
  Info,
  Instagram,
  Linkedin,
  LogOut,
  Lock,
  Map,
  MapPin,
  Menu,
  MessageCircle,
  MinusCircle,
  Minus,
  MoreHorizontal,
  Move,
  Package,
  PauseCircle,
  Percent,
  PhoneIncoming,
  Plus,
  Printer,
  RotateCcw,
  RotateCw,
  Search,
  Send,
  Settings,
  Shield,
  ShoppingCart,
  Sliders,
  Square,
  Star,
  Tablet,
  Target,
  Trash2,
  TrendingUp,
  Truck,
  Twitter,
  Upload,
  UploadCloud,
  User,
  UserCheck,
  Users,
  UserPlus,
  X,
  XCircle,
  XSquare
} from 'react-feather'

// These custom icons are functionally the same as Feather icons
import Shop from './Shop'
import Pinterest from './Pinterest'

export const iconList = [
  AlertCircle,
  AlertTriangle,
  AlertOctagon,
  ArrowDown,
  ArrowLeft,
  ArrowLeftCircle,
  ArrowRight,
  ArrowUp,
  Award,
  CreditCard,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Circle,
  Clipboard,
  Clock,
  Copy,
  DollarSign,
  Download,
  Edit,
  ExternalLink,
  Eye,
  Facebook,
  Feather,
  FileText,
  Filter,
  HelpCircle,
  Home,
  Image,
  Info,
  Instagram,
  Linkedin,
  LogOut,
  Lock,
  Map,
  MapPin,
  Menu,
  MessageCircle,
  MinusCircle,
  Minus,
  MoreHorizontal,
  Move,
  Package,
  PauseCircle,
  Percent,
  PhoneIncoming,
  Plus,
  Printer,
  Search,
  Send,
  Settings,
  Shield,
  ShoppingCart,
  Sliders,
  Square,
  Star,
  Tablet,
  Target,
  Trash2,
  TrendingUp,
  Truck,
  Twitter,
  Upload,
  UploadCloud,
  User,
  UserCheck,
  Users,
  UserPlus,
  X,
  XCircle,
  XSquare,
  Shop,
  Pinterest,
  RotateCcw,
  RotateCw
]

export const iconsIndexed = keyBy(iconList, i => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return kebabCase((i as any).displayName)
})

export type IconProps = {
  className?: string
  kind?: string
  color?: string
  fillColor?: string
  size?: number
  strokeWidth?: number
  onClick?: () => void
}

const Icon = ({
  className,
  kind = 'feather',
  fillColor = 'none',
  color = fillColor !== 'none' ? 'white' : 'currentColor',
  size = 24,
  strokeWidth = 2,
  onClick
}: IconProps) => {
  const IconComponent = iconsIndexed[kind as keyof typeof iconsIndexed]
  if (IconComponent)
    return (
      <IconComponent
        className={className}
        color={color}
        fill={fillColor}
        size={size}
        onClick={onClick}
        strokeWidth={strokeWidth}
      />
    )
  else return <></>
}

export default Icon
